import { Heading, Link } from "@chakra-ui/react"
import React, { FC } from "react"
import { ColoredText } from "../ColoredText"
import { FormBox, FormVStack } from "../Form"
import { Layout2 } from "./Layout2"
import { VStretch } from "../layout/Stretch"

export const FormLayout2: FC<{
  title: string
  showBack?: boolean
}> = ({ title, showBack = false, children }) => {
  return (
    <Layout2>
      <VStretch width="100%" maxW="md" mx="auto">
        <FormBox>
          <FormVStack>
            {showBack && (
              <ColoredText colorScheme="blue" fontWeight="bold" fontSize="lg">
                <Link onClick={() => window.history.back()} textDecor="none">
                  ← Back
                </Link>
              </ColoredText>
            )}
            <Heading>{title}</Heading>
            {children}
          </FormVStack>
        </FormBox>
      </VStretch>
    </Layout2>
  )
}
