import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import React from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { FormVStack } from "../../components/Form"
import { FormLayout2 } from "../../components/vlayout/FormLayout2"

export default function Form2Page() {
  return (
    <DebugLayout title="Form 2" flush>
      <FormLayout2 title="Form 2">
        <form onSubmit={e => e.preventDefault()}>
          <FormVStack>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input required size="lg" type="email" autoFocus />
              <FormHelperText>
                Your email won't be shared with anyone.
              </FormHelperText>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input required size="lg" type="password" />
            </FormControl>
            <Button type="submit" colorScheme="green" size="lg">
              Continue
            </Button>
          </FormVStack>
        </form>
      </FormLayout2>
    </DebugLayout>
  )
}
