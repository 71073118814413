import React, { FC } from "react"
import { Flex, FlexProps } from "@chakra-ui/react"

export const HStretch: FC<FlexProps> = ({ ...props }) => (
  <Flex
    flexDir="row"
    flexGrow={1}
    flexShrink={0}
    justifyContent="stretch"
    alignItems="stretch"
    {...props}
  />
)

export const VStretch: FC<FlexProps> = ({ ...props }) => (
  <Flex
    flexDir="column"
    flexGrow={1}
    flexShrink={0}
    justifyContent="stretch"
    alignItems="stretch"
    // width="100%"
    // height="100%"
    {...props}
  />
)
